import PropTypes from 'prop-types';

export const BreederPostCardPropTypes = {
  author: PropTypes.shape({
    breeder_breed_is: PropTypes.arrayOf(PropTypes.number),
    display_name: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    profile_photo_url: PropTypes.string,
    user_id: PropTypes.number
  }),
  content: PropTypes.string,
  created_at: PropTypes.string,
  id: PropTypes.number,
  like_count: PropTypes.number,
  litter_tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      parents: PropTypes.arrayOf(
        PropTypes.shape({
          gender: PropTypes.string,
          profile_photo_url: PropTypes.string
        })
      )
    })
  ),
  media_files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.oneOf(['Photograph', 'Video']),
      url: PropTypes.string,
      thumbnail_url: PropTypes.string
    })
  ),
  type: PropTypes.string
};
