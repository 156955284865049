export function breederPostsQueryDefinition(breederProfileId, params) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/breeder_posts.json`,
    params
  };
}

export function breederPostQueryDefinition(breederProfileId, breederPostId) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/breeder_posts/${breederPostId}.json`
  };
}

export function createBreederPostsMutationDefinition(breederProfileId) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/breeder_posts.json`,
    method: 'POST'
  };
}

export function deleteBreederPostMutationDefinition(
  breederProfileId,
  breederPostId
) {
  return {
    url: `/api/breeder_app/breeder_profiles/${breederProfileId}/breeder_posts/${breederPostId}.json`,
    method: 'DELETE'
  };
}

export function userReactionsQueryDefinition(params) {
  return {
    url: '/api/user_reactions.json',
    params,
    defaultValue: {
      data: [],
      meta: {}
    }
  };
}

export function createUserReactionMutationDefinition() {
  return {
    url: '/api/user_reactions.json',
    method: 'POST'
  };
}

export function removeUserReactionMutationDefinition(userReactionId) {
  return {
    url: `/api/user_reactions/${userReactionId}.json`,
    method: 'DELETE'
  };
}
