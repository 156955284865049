// V2: follow a breeder profile not specific to a breeder breed
import { useMemo } from 'react';

import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import useResourceMutation from 'dpl/shared/fetching/hooks/useResourceMutation';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';

import {
  userFollowsQueryDefinition,
  createBreederProfileFollowMutationDefinition,
  removeBreederProfileFollowMutationDefinition
} from '../utils/resourceDefinitions';
import { FOLLOWABLE_TYPES } from '../utils/constants';

export default function useUserFollowsDataV2(
  breederBreedIds,
  breederProfileId
) {
  const { isBuyer, value: currentUser } = useCurrentUser();

  const buyerProfileId = currentUser?.data?.buyer_profile_id;

  const userFollowsData = useResourceQuery({
    ...userFollowsQueryDefinition({
      followable_type: FOLLOWABLE_TYPES.BREEDER_BREED
    }),
    enabled: Boolean(isBuyer)
  });

  const isFollowingBreeder = useMemo(() => {
    if (breederBreedIds.length > 0) {
      return breederBreedIds.some(id =>
        userFollowsData.value.data.some(f => f.followable_id === id)
      );
    }

    return false;
  }, [userFollowsData]);

  const { mutateAsync: createBreederProfileUserFollow, isLoading: isCreating } =
    useResourceMutation(
      createBreederProfileFollowMutationDefinition(buyerProfileId)
    );

  const { mutateAsync: removeBreederProfileFollow, isLoading: isRemoving } =
    useResourceMutation(
      removeBreederProfileFollowMutationDefinition(
        breederProfileId,
        buyerProfileId
      )
    );

  return {
    userFollowsData,
    createBreederProfileUserFollow,
    removeBreederProfileFollow,
    isFollowingBreeder,
    isLoading: userFollowsData.isLoading,
    isUpdating: isCreating || isRemoving
  };
}
