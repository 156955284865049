import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ClickableDiv from 'dpl/common/components/ClickableDiv';
import Icon from 'dpl/common/components/Icon';
import SmartImage from 'dpl/components/SmartImage';

import useBreederPostData from '../hooks/useBreederPostData';

function getViewMoreButtonLabel({ totalCount, visibleCount }) {
  if (totalCount - visibleCount === 1) {
    return '+1';
  }

  if (totalCount > 1 && totalCount > visibleCount) {
    return `+${totalCount - visibleCount}`;
  }

  return null;
}

export default function BreederPostCardMediaGrid({
  className,
  gallery,
  isLargeGrid,
  breederPostId,
  breederProfileId
}) {
  const { openPostModal } = useBreederPostData();

  const maxThumbnails = isLargeGrid ? 5 : 3;
  const maxVisibleThumbnailsCount =
    !isLargeGrid && gallery.length === 2 ? 1 : maxThumbnails;

  const visibleThumbnails = gallery.slice(0, maxVisibleThumbnailsCount);

  const totalThumbnailCount = gallery.length;
  const visibleThumbnailCount = visibleThumbnails.length;

  const viewMoreButtonLabel = getViewMoreButtonLabel({
    totalCount: totalThumbnailCount,
    visibleCount: visibleThumbnailCount
  });

  const galleryClassname = isLargeGrid
    ? 'BreederPostCardMediaGrid__gallery--large'
    : 'BreederPostCardMediaGrid__gallery--small';
  const videoIconSize = isLargeGrid ? '32px' : '20px';

  function openMediaCarousel(slideIndex) {
    openPostModal(breederPostId, breederProfileId, slideIndex);
  }

  function handleViewMoreClick(e) {
    e.stopPropagation();

    if (gallery.length === 1) {
      openMediaCarousel(1);
    } else {
      openMediaCarousel(maxVisibleThumbnailsCount);
    }
  }

  return (
    <div
      className={classnames(
        'BreederPostCardMediaGrid overflow-hidden relative',
        className
      )}
      data-test-id="breeder-post-card-media-grid"
      data-thumbnails-visible={totalThumbnailCount > 1}
    >
      {gallery.length > 0 && (
        <div
          className={`BreederPostCardMediaGrid__gallery ${galleryClassname} ${galleryClassname}-${visibleThumbnails.length}`}
        >
          {visibleThumbnails.map((galleryThumbnail, index) => {
            const isVideo = galleryThumbnail.type === 'Video';
            return (
              <ClickableDiv
                key={galleryThumbnail.id}
                className={`${galleryClassname}-thumbnail relative`}
                onClick={e => {
                  e.stopPropagation();
                  openMediaCarousel(index);
                }}
              >
                <SmartImage
                  className="h-100 w-100 object-cover"
                  loadingClass="bg-light-gray"
                  crop
                  src={
                    isVideo
                      ? galleryThumbnail.thumbnail_url
                      : galleryThumbnail.url
                  }
                />
                {isVideo && (
                  <span
                    className={classnames('bg-black-60 br-100', {
                      'pv4 ph4 transform-center-all': isLargeGrid,
                      'pv2 ph2 absolute left-0 bottom-0 mv2 mh2': !isLargeGrid
                    })}
                  >
                    <Icon
                      width={videoIconSize}
                      height={videoIconSize}
                      name="fetch-play"
                      className="white"
                    />
                  </span>
                )}
              </ClickableDiv>
            );
          })}
        </div>
      )}
      {viewMoreButtonLabel && (
        <button
          type="button"
          className="BreederPostCardMediaGrid__view-more br-pill font-14 fw-medium white absolute bottom-0 right-0 mv2 mh2 pv2 ph4"
          onClick={handleViewMoreClick}
        >
          {viewMoreButtonLabel}
        </button>
      )}
    </div>
  );
}

BreederPostCardMediaGrid.propTypes = {
  className: PropTypes.string,
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['Photograph', 'Video']),
      id: PropTypes.number,
      caption: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired,
  isLargeGrid: PropTypes.bool.isRequired,
  breederPostId: PropTypes.number.isRequired,
  breederProfileId: PropTypes.number.isRequired
};

BreederPostCardMediaGrid.defaultProps = {
  className: null
};
