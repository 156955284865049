import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';

import { breederProfileFollowersResourceDefinition } from '../utils/resourceDefinitions';

export default function useFollowersData(breederProfileId) {
  const followersData = useResourceQuery(
    breederProfileFollowersResourceDefinition(breederProfileId)
  );

  const numOfFollowers = followersData?.value?.total_followers_count;

  return {
    followersData,
    hasFollowers: numOfFollowers > 0,
    numOfFollowers,
    isLoadingFollowers: followersData.isLoading
  };
}
