import { useMemo } from 'react';

import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import useResourceMutation from 'dpl/shared/fetching/hooks/useResourceMutation';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';

import {
  createUserReactionMutationDefinition,
  removeUserReactionMutationDefinition,
  userReactionsQueryDefinition
} from './resourceDefinitions';

export default function useUserReactionsData(reactionableId) {
  const { isLoggedIn } = useCurrentUser();

  const userReactionsData = useResourceQuery({
    ...userReactionsQueryDefinition(),
    enabled: isLoggedIn
  });

  const userReaction = useMemo(() => {
    return userReactionsData.value.data.find(
      r => r.reactionable_id === reactionableId
    );
  }, [userReactionsData]);

  const { mutateAsync: createUserReaction } = useResourceMutation(
    createUserReactionMutationDefinition()
  );

  const { mutateAsync: removeUserReaction } = useResourceMutation(
    removeUserReactionMutationDefinition(userReaction?.id)
  );

  return {
    userReactionsData,
    createUserReaction,
    removeUserReaction,
    userHasReacted: Boolean(userReaction),
    isLoading: userReactionsData.isLoading
  };
}
