import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import { isGreaterThanBreakpoint } from 'dpl/util/grid';
import useResourceMutation from 'dpl/shared/fetching/hooks/useResourceMutation';
import GenericMenu from 'dpl/path_to_payment/components/GenericMenu';
import Icon from 'dpl/common/components/Icon';
import FixedTooltipPortal from 'dpl/common/tooltip/components/FixedTooltipPortal';

import { deleteBreederPostMutationDefinition } from '../utils/resourceDefinitions';

const IS_GT_MD = isGreaterThanBreakpoint('md');

export default function BreederPostCardMenu({
  breederProfileId,
  breederPostId,
  onDeletePost
}) {
  const [isMenuOpen, toggleIsMenuOpen] = useReducer(isOpen => !isOpen, false);

  const { mutateAsync: deleteBreederPost, isLoading: isRemoving } =
    useResourceMutation(
      deleteBreederPostMutationDefinition(breederProfileId, breederPostId)
    );

  function handleDeletePost(e) {
    e.stopPropagation();

    deleteBreederPost().then(() => {
      toggleIsMenuOpen();
      onDeletePost();
    });
  }

  return (
    <>
      <button
        type="button"
        className="self-start ml-auto br-100 ba b--default pv1 ph1"
        data-test-id="breeder-post-menu"
        onClick={e => {
          e.stopPropagation();
          toggleIsMenuOpen();
        }}
      >
        <Icon name="fetch-options" height="20px" width="20px" />
      </button>

      {IS_GT_MD && isMenuOpen ? (
        <GenericMenu
          className="BreederPostCard__menu z-max"
          setIsMenuOpen={toggleIsMenuOpen}
          closeOnClickOutside
        >
          <button
            type="button"
            className="font-16 fw-medium pv4 flex items-center"
            disabled={isRemoving}
            onClick={handleDeletePost}
          >
            Delete update
          </button>
        </GenericMenu>
      ) : (
        <FixedTooltipPortal
          isVisible={isMenuOpen}
          onCloseClick={() => toggleIsMenuOpen()}
          shouldHideCloseButton
        >
          <GenericMenu setIsMenuOpen={toggleIsMenuOpen}>
            <button
              type="button"
              className="font-16 fw-medium pv4 flex items-center"
              disabled={isRemoving}
              onClick={handleDeletePost}
            >
              Delete update
            </button>
          </GenericMenu>
        </FixedTooltipPortal>
      )}
    </>
  );
}

BreederPostCardMenu.propTypes = {
  breederProfileId: PropTypes.number.isRequired,
  breederPostId: PropTypes.number.isRequired,
  onDeletePost: PropTypes.func.isRequired
};
