import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { isMobileUA } from 'dpl/shared/utils';
import { ANALYTICS_VIEWS } from 'dpl/constants/analytics';
import OverlayModal from 'dpl/components/OverlayModal';
import MiniOverlayModal from 'dpl/common/mini_overlay_modal/components/MiniOverlayModal';
import UserAvatar from 'dpl/path_to_payment/components/UserAvatar';
import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';
import MediaCarousel from 'dpl/components/MediaCarousel';
import LoadingWrapper from 'dpl/components/LoadingWrapper';
import Icon from 'dpl/common/components/Icon';
import FixedScrollWrapper from 'dpl/components/FixedScrollWrapper';

import LikeButton from './LikeButton';
import useBreederPostData from '../hooks/useBreederPostData';

const IS_MOBILE_UA = isMobileUA();

export function BreederPostDetails({
  breederProfile,
  content,
  collapseText,
  createdAtDate,
  fadeToBackgroundColor,
  moreButtonClassName
}) {
  return (
    <>
      <div className="flex items-center">
        <UserAvatar
          className="flex-none"
          user={{ ...breederProfile, id: breederProfile.user_id }}
          shape="round"
        />
        <div className="ml2">
          <p className="font-14 fw-medium">{breederProfile.display_name}</p>
          <p className="font-14 o-80">{createdAtDate}</p>
        </div>
      </div>
      {content && collapseText && (
        <ClampedMultiLineText
          className="font-14 lh-body pre-wrap mt3"
          numOfLines={6}
          lineHeight={19.6}
          text={content}
          moreText="Read more"
          lessText="Show less"
          moreButtonClassName={moreButtonClassName}
          fadeToBackgroundColor={fadeToBackgroundColor}
          fadeToButton
        />
      )}
      {content && !collapseText && (
        <p className="font-14 lh-body pre-wrap mt3">{content}</p>
      )}
    </>
  );
}

BreederPostDetails.propTypes = {
  breederProfile: PropTypes.shape({
    display_name: PropTypes.string,
    user_id: PropTypes.number
  }).isRequired,
  collapseText: PropTypes.bool,
  content: PropTypes.string,
  createdAtDate: PropTypes.string.isRequired,
  fadeToBackgroundColor: PropTypes.string.isRequired,
  moreButtonClassName: PropTypes.string
};

BreederPostDetails.defaultProps = {
  collapseText: false,
  content: null,
  moreButtonClassName: 'white'
};

export default function BreederPostModal() {
  const {
    breederPost,
    isBreederPostModalOpen,
    isLoading,
    onModalClose,
    refetchBreederPost,
    selectedMediaIdx
  } = useBreederPostData();

  const {
    author: breederProfile,
    content,
    created_at: createdAtDate,
    like_count: numOfLikes,
    litter_tags: litterTags,
    media_files: gallery
  } = breederPost;

  const muxRef = useRef(null);
  const [currentFile, setCurrentFile] = useState(null);

  useEffect(() => {
    if (gallery?.length > 0) {
      setCurrentFile(gallery[selectedMediaIdx]);
    }
  }, [gallery, selectedMediaIdx]);

  if (!isBreederPostModalOpen) {
    return null;
  }

  function handleFileChange(imageId) {
    const idx = gallery.findIndex(file => file.id === imageId);
    setCurrentFile(gallery[idx]);
  }

  const commonAnalytics = {
    litter_id: litterTags?.[0]?.id,
    view: ANALYTICS_VIEWS.BREEDER_UPDATES_MODAL
  };

  return (
    <div className="absolute--fill fixed">
      <LoadingWrapper isLoading={isLoading} className="transform-center-all">
        {() => {
          return gallery?.length > 0 ? (
            <OverlayModal
              className="BreederPostModal"
              onClose={onModalClose}
              hideCloseButton
              wrapper="div"
            >
              <button
                type="button"
                onClick={onModalClose}
                className="z-max absolute top-1 right-1 gray-600"
              >
                <Icon name="fetch-close" height="24px" width="24px" />
              </button>
              {/* mobile */}
              <FixedScrollWrapper className="BreederPostModal__mobileContainer bg-default white h-100">
                <div className="ph5 pt14 pb5">
                  <BreederPostDetails
                    breederProfile={breederProfile}
                    createdAtDate={createdAtDate}
                    collapseText
                    content={content}
                    fadeToBackgroundColor="#040415"
                  />
                </div>
                <div className="mb20 flex-grow-1">
                  <MediaCarousel
                    arrowSize="24px"
                    muxRef={muxRef}
                    currentFile={currentFile}
                    files={gallery}
                    onFileChange={handleFileChange}
                    maxBulletsPadding={10}
                    smartImageProps={{
                      wrapperOnlyClassName: 'w-100',
                      loadingClass: 'h-100 loading-spinner'
                    }}
                  />
                </div>
                <div className="fixed bottom-0 w-100 bg-default z-999 ph5 pv5 icon-default">
                  <LikeButton
                    numOfLikes={numOfLikes}
                    postId={breederPost.id}
                    onLike={refetchBreederPost}
                  />
                </div>
              </FixedScrollWrapper>
              {/* desktop */}
              <div className="BreederPostModal__container w-100 h-100">
                <div className="bg-default h-100 overflow-hidden">
                  <MediaCarousel
                    className="ph10-md pv10-md"
                    arrowSize="24px"
                    muxRef={muxRef}
                    currentFile={currentFile}
                    files={gallery}
                    onFileChange={handleFileChange}
                    maxBulletsPadding={10}
                    smartImageProps={{
                      className:
                        'transform-center max-h-100 h-100 h-auto left-0 right-0 center w-100 w-auto-ns',
                      imageOnlyClassName: 'max-h-100 h-auto w-100 w-auto-ns',
                      wrapperOnlyClassName: 'w-100 w-80-lg',
                      loadingClass: 'h-100 loading-spinner'
                    }}
                  />
                </div>
                <div className="flex flex-column bg-gray-800 white">
                  <div className="pt10 pb4 ph5 bb b--tertiary">
                    <BreederPostDetails
                      breederProfile={breederProfile}
                      createdAtDate={createdAtDate}
                      collapseText
                      content={content}
                      fadeToBackgroundColor="#232323"
                    />
                  </div>
                  {/* future comments section */}
                  <div className="flex-grow-1" />
                  <div className="bt b--tertiary pv3 ph4 icon-default">
                    <LikeButton
                      analytics={commonAnalytics}
                      numOfLikes={numOfLikes}
                      postId={breederPost.id}
                      onLike={refetchBreederPost}
                    />
                  </div>
                </div>
              </div>
            </OverlayModal>
          ) : (
            <MiniOverlayModal
              className="BreederPostMiniModal"
              hideCloseButton
              maxWidth="600px"
              scrollableContent={IS_MOBILE_UA}
            >
              <button
                type="button"
                onClick={onModalClose}
                className="absolute top-1 right-1 secondary br-100 ba b--light-gray bg-white"
                style={{ padding: '6px' }}
              >
                <Icon name="fetch-close" height="20px" width="20px" />
              </button>
              <div className="pt8 pb4 ph8">
                <BreederPostDetails
                  breederProfile={breederProfile}
                  createdAtDate={createdAtDate}
                  content={content}
                  moreButtonClassName="black"
                  fadeToBackgroundColor="#ffffff"
                />
              </div>
              <div className="flex items-center font-14 icon-default bt b--gray-300 pt3 pb8 ph8">
                <LikeButton
                  analytics={commonAnalytics}
                  numOfLikes={numOfLikes}
                  postId={breederPost.id}
                  onLike={refetchBreederPost}
                />
              </div>
            </MiniOverlayModal>
          );
        }}
      </LoadingWrapper>
    </div>
  );
}
