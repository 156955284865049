import React from 'react';
import PropTypes from 'prop-types';

import VennImages from 'dpl/components/VennImages';
import { getOrderedParents } from 'dpl/shared/utils/breeder_app';

export default function LitterParentsVennImages({
  litterParents,
  vennImageProps
}) {
  return (
    <VennImages
      {...vennImageProps}
      images={getOrderedParents(litterParents).map(parent => ({
        src: parent.profile_photo_url,
        alt: parent.name ?? 'Unnamed'
      }))}
    />
  );
}

LitterParentsVennImages.propTypes = {
  litterParents: PropTypes.arrayOf(
    PropTypes.shape({
      profile_photo_url: PropTypes.string,
      name: PropTypes.string,
      gender: PropTypes.string.isRequired
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  vennImageProps: PropTypes.any
};

LitterParentsVennImages.defaultProps = {
  vennImageProps: {}
};
