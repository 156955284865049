import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { sendRudderstackEvent } from 'dpl/util/analytics';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_INTERACTION_TYPES,
  ANALYTICS_VIEW_FEATURES
} from 'dpl/constants/analytics';
import Icon from 'dpl/common/components/Icon';
import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import BuyerAuthModal from 'dpl/buyer_account/components/BuyerAuthModal';

import useUserReactionsData from '../utils/useUserReactionsData';

export default function LikeButton({ analytics, numOfLikes, postId, onLike }) {
  const {
    isLoading,
    userHasReacted: isLikedPost,
    createUserReaction,
    removeUserReaction,
    userReactionsData
  } = useUserReactionsData(postId);

  const currentUser = useCurrentUser();
  const { isLoggedIn } = currentUser;

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [likes, setLikes] = useState(numOfLikes);
  const [isLiked, setIsLiked] = useState(isLikedPost);

  useEffect(() => {
    setIsLiked(isLikedPost);
  }, [isLikedPost]);

  useEffect(() => {
    setLikes(numOfLikes);
  }, [numOfLikes]);

  if (isLoading) {
    return <SkeletonBlock className="mr1" height="24px" width="24px" />;
  }

  const iconName = isLiked ? 'fetch-heart-full' : 'fetch-heart-alt';

  const commonAnalytics = {
    view_feature: ANALYTICS_VIEW_FEATURES.HEART_ICON,
    ...analytics
  };

  async function updateUserReaction() {
    setIsLiked(!isLiked);

    if (!isLiked) {
      setLikes(likes + 1);

      sendRudderstackEvent(ANALYTICS_EVENTS.SOCIAL_POST_INTERACTION_BY_USER, {
        interaction_type: ANALYTICS_INTERACTION_TYPES.LIKE,
        breeder_post_id: postId,
        ...commonAnalytics
      });

      await createUserReaction({
        body: {
          reactionable_type: 'BreederPost',
          reactionable_id: postId,
          reaction_type: 'like'
        }
      });
    } else {
      setLikes(likes - 1);

      sendRudderstackEvent(ANALYTICS_EVENTS.SOCIAL_POST_INTERACTION_BY_USER, {
        interaction_type: ANALYTICS_INTERACTION_TYPES.UNLIKE,
        ...commonAnalytics
      });

      await removeUserReaction();
    }

    await userReactionsData.refetch();
    onLike();
  }

  async function handleUserLogin() {
    setShowLoginModal(false);
    const { data: loggedInUser } = await currentUser.refetch();
    if (loggedInUser.data?.data?.user_id) {
      const { data: userReactions } = await userReactionsData.refetch();
      const userHasReacted = userReactions?.data?.data?.some(
        r => r.reactionable_id === postId
      );

      if (!userHasReacted) {
        updateUserReaction();
      }
    }
  }

  function handleLikeClick(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!isLoggedIn) {
      setShowLoginModal(true);
    } else {
      updateUserReaction();
    }
  }

  return (
    <>
      <button
        type="button"
        className="flex items-center"
        data-test-id={`like-btn-${postId}`}
        disabled={isLoading}
        onClick={handleLikeClick}
      >
        <Icon
          className={classnames('mr1', {
            'rubber-dark-mid': isLiked
          })}
          name={iconName}
        />
        {Boolean(likes) && <p className="font-14 fw-medium">{likes}</p>}
      </button>
      {showLoginModal && (
        <BuyerAuthModal
          onCloseClick={() => setShowLoginModal(false)}
          onSuccessfulAuth={handleUserLogin}
        />
      )}
    </>
  );
}

LikeButton.propTypes = {
  analytics: PropTypes.shape({
    breeder_post_id: PropTypes.number,
    view: PropTypes.string,
    view_feature_location: PropTypes.string
  }),
  numOfLikes: PropTypes.number,
  onLike: PropTypes.func,
  postId: PropTypes.number.isRequired
};

LikeButton.defaultProps = {
  analytics: {},
  numOfLikes: 0,
  onLike: null
};
