import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ClickableDiv from 'dpl/common/components/ClickableDiv';
import { pushParams } from 'dpl/actions/query_params';
import { LITTER_ID } from 'dpl/constants/query_params';
import LitterParentsVennImages from 'dpl/breeder_dashboard/components/LitterParentsVennImages';

const VENN_IMAGE_PROPS = {
  width: '24px',
  height: '24px',
  className: 'dib flex-none',
  isBordered: true,
  isOverlapping: true,
  isSmall: true
};

export default function BreederPostLitterTag({ isVerticalLayout, litter }) {
  const dispatch = useDispatch();

  function onLitterClick(litterId, e) {
    e.stopPropagation();

    dispatch(pushParams({ [LITTER_ID]: litterId }, { hash: true }));
  }

  return (
    <ClickableDiv
      className={classnames(
        'BreederPostCard__tag flex items-center br3 ba b--gray-300 ph3 pv3',
        {
          'w-50-md w-40-lg': isVerticalLayout
        }
      )}
      data-test-id={`breeder-post-litter-${litter.id}-tag`}
      onClick={e => onLitterClick(litter.id, e)}
    >
      <LitterParentsVennImages
        litterParents={litter.parents}
        vennImageProps={VENN_IMAGE_PROPS}
      />
      <p className="ml2 font-16">{litter.name}&apos;s litter</p>
    </ClickableDiv>
  );
}

BreederPostLitterTag.propTypes = {
  isVerticalLayout: PropTypes.bool,
  litter: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    parents: PropTypes.arrayOf(
      PropTypes.shape({
        profile_photo_url: PropTypes.string,
        name: PropTypes.string,
        gender: PropTypes.string.isRequired
      })
    )
  }).isRequired
};

BreederPostLitterTag.defaultProps = {
  isVerticalLayout: false
};
