// follow a breeder profile not specific to a breeder breed
import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import BuyerAuthModal from 'dpl/buyer_account/components/BuyerAuthModal';
import Icon from 'dpl/common/components/Icon';
import LoadingDots from 'dpl/components/LoadingDots';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_USER_JOURNEYS
} from 'dpl/constants/analytics';
import { sendRudderstackEvent } from 'dpl/util/analytics';

import useUserFollowsDataV2 from '../hooks/useUserFollowsDataV2';
import useFollowersData from '../hooks/useFollowersData';

const DEFAULT_ICON_SIZE = '24px';

const DEFAULT_ICON_PROPS = {
  CHECKED: {
    name: 'fetch-favorite-fill',
    className: 'rubber-dark-mid',
    height: DEFAULT_ICON_SIZE,
    width: DEFAULT_ICON_SIZE
  },
  UNCHECKED: {
    name: 'fetch-heart-alt',
    className: 'stone-700',
    height: DEFAULT_ICON_SIZE,
    width: DEFAULT_ICON_SIZE
  }
};

export default function BreederProfileFollowButton({
  analytics,
  className,
  breederBreedIds,
  breederProfileId,
  withIcon
}) {
  const [showLoginModal, setShowLoginModal] = useState(false);

  const currentUser = useCurrentUser();
  const { isLoggedIn, value: currentUserData } = currentUser;
  const { followersData } = useFollowersData(breederProfileId);

  const {
    createBreederProfileUserFollow,
    removeBreederProfileFollow,
    userFollowsData,
    isFollowingBreeder,
    isLoading,
    isUpdating
  } = useUserFollowsDataV2(breederBreedIds, breederProfileId);

  const iconProps = isFollowingBreeder
    ? DEFAULT_ICON_PROPS.CHECKED
    : DEFAULT_ICON_PROPS.UNCHECKED;
  const label = isFollowingBreeder ? 'Following' : 'Follow';

  if (withIcon && isLoading) {
    return (
      <div className={className}>
        <LoadingDots height="20px" isVisible />
      </div>
    );
  }

  async function updateFavorite(buyerProfileID) {
    if (!isFollowingBreeder) {
      const eventName = ANALYTICS_EVENTS.BREEDER_ADDED_TO_FAVORITES;

      sendRudderstackEvent(eventName, {
        buyer_profile_id:
          currentUserData.data?.buyer_profile_id || buyerProfileID,
        user_journey: ANALYTICS_USER_JOURNEYS.BUYER_BROWSING,
        ...analytics
      });

      try {
        await createBreederProfileUserFollow({
          body: {
            breeder_profile_id: breederProfileId
          }
        });
      } finally {
        await userFollowsData.refetch();
        await followersData.refetch();
      }
    } else {
      const eventName = ANALYTICS_EVENTS.BREEDER_REMOVED_FROM_FAVORITES;

      sendRudderstackEvent(eventName, {
        buyer_profile_id: currentUserData.data.buyer_profile_id,
        breeder_profile_id: breederProfileId,
        user_journey: ANALYTICS_USER_JOURNEYS.BUYER_BROWSING,
        ...analytics
      });

      try {
        await removeBreederProfileFollow();
      } finally {
        await userFollowsData.refetch();
        await followersData.refetch();
      }
    }
  }

  async function handleUserLogin() {
    setShowLoginModal(false);
    const { data: loggedInUser } = await currentUser.refetch();
    if (loggedInUser?.data?.data?.buyer) {
      const { data: userFollows } = await userFollowsData.refetch();
      const userAlreadyFollows = breederBreedIds.some(bb =>
        userFollows?.data?.data?.some(f => f.followable_id === bb)
      );
      if (!userAlreadyFollows) {
        const buyerProfileID = loggedInUser.data.data.buyer_profile_id;
        updateFavorite(buyerProfileID);
      }
    }
  }

  function handleFavoriteClick(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!isLoggedIn) {
      setShowLoginModal(true);
    } else {
      updateFavorite();
    }
  }

  return (
    <>
      <button
        type="button"
        className={className}
        onClick={handleFavoriteClick}
        disabled={isLoading || isUpdating}
      >
        {withIcon && <Icon {...iconProps} />}
        <p
          className={classnames({
            ml2: withIcon
          })}
        >
          {label}
        </p>
      </button>
      {showLoginModal && (
        <BuyerAuthModal
          onCloseClick={() => setShowLoginModal(false)}
          onSuccessfulAuth={handleUserLogin}
        />
      )}
    </>
  );
}

BreederProfileFollowButton.propTypes = {
  analytics: PropTypes.shape({
    breeder_name: PropTypes.string,
    view: PropTypes.string,
    view_feature: PropTypes.string,
    view_feature_location: PropTypes.string
  }),
  breederBreedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  breederProfileId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  className: PropTypes.string,
  withIcon: PropTypes.bool
};

BreederProfileFollowButton.defaultProps = {
  analytics: {},
  className: null,
  withIcon: false
};
