import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { makeSequence } from 'dpl/shared/utils';

export default function LoadingDots({ isVisible, direction, height }) {
  return (
    <div
      className={classnames('LoadingDots overflow-hidden', {
        'LoadingDots--visible': isVisible
      })}
    >
      <div
        className="LoadingDots__container center transition flex items-center"
        style={{
          height,
          [direction === 'up' ? 'marginBottom' : 'marginTop']:
            !isVisible && `-${height}`
        }}
      >
        {makeSequence(3).map(idx => (
          <div
            key={idx}
            className={`
                  fl ml1
                  LoadingDots__dot
                  LoadingDots__dot_${idx + 1}
                `}
          />
        ))}
      </div>
    </div>
  );
}

LoadingDots.propTypes = {
  height: PropTypes.string,
  direction: PropTypes.string,
  isVisible: PropTypes.bool.isRequired
};

LoadingDots.defaultProps = {
  height: '60px',
  direction: 'down'
};
